// Imports
// ------
import React from 'react';
import { motion } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Component
// ------
function PageTransition({ children, status, entry, exit }) {
	const bp = useBreakpoint();

	const isExiting = status === 'exiting';
	const hasEntered = status === 'entered';
	const isEntering = status === 'entering';
	const isInitial = hasEntered ? hasEntered : isEntering;
	const transitionProps = {
		initial: isInitial ? { opacity: 1 } : { opacity: 0 },
		animate: isExiting
			? { opacity: 0 }
			: { opacity: 1, transition: { delay: bp.large ? 0.5 : 1 } },
		transition: isExiting
			? { duration: bp.large ? 0.5 : 1 }
			: { duration: bp.large ? 0.5 : 1 },
	};

	return <motion.div {...transitionProps}>{children}</motion.div>;
}

export default React.memo(PageTransition);
